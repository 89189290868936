import successIconNew from '../../assets/sucsessNew.svg'
import warningNew from '../../assets/warningNew.svg';
import fraudNew from '../../assets/fraudNew.svg';
// import { ActionStatuses } from '../enum/actionStatuses';
import { AntiSpoofStatus } from "../enum/AntiSpoofStatus";

export const statusesMap = new Map([
  [
    AntiSpoofStatus.WARNING,
    {
      text: 'Not verified',
      icon: warningNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EBB11D',
    },
  ],
  [
    AntiSpoofStatus.FRAUD,
    {
      text: 'Fraud',
      icon: fraudNew,
      isSuccess: false,
      btnText: 'Try again',
      type: 'hide',
      color: '#EA424A',
    },
  ],
  [
    AntiSpoofStatus.VERIFIED,
    {
      text: 'Verified Human',
      icon: successIconNew,
      isSuccess: true,
      btnText: 'OK',
      type: 'hide',
      color: '#227F37',
    },
  ],
  [
    AntiSpoofStatus.ERROR,
    {
      text: 'Something went wrong!',
      subText: 'Please try again',
      icon: fraudNew,
      color: '#EA424A',
    },
  ]
]);
