import React, { FC } from "react";
import cn from "classnames";
import { AntiSpoofStatus } from "../../../../utils/enum/AntiSpoofStatus";

import styles from "./callIdentify.module.scss";
import { statusesMap } from "../../../../utils/helpers/statuses";
// import Button from '../Button/Button';
// import { useNavigate } from 'react-router-dom';
// import { emitCommand, EmitCommandParams } from '../../../../utils/helpers/emitCommand';
// import { TaskType } from '../../../../utils/enum/taskType';
// import { useCallInfoStore } from '../../../../stores/useCallInfo';
// import { ActionStatuses } from "../../../../utils/enum/actionStatuses";
// import { useVadInfoStore } from '../../../../stores/useVadllInfo';
// import { useConnectionStore } from '../../../../stores/useConnection';
// import { Socket } from 'socket.io-client';

type ButtonProps = {
  result: AntiSpoofStatus;
};

const CallIdentify: FC<ButtonProps> = ({ result }) => {
  // const navigate = useNavigate();

  // const { text, icon, isSuccess, btnText, isEnroll, type, color } = statusesMap?.get(result) || {};

  const { text, icon, color, subText } = statusesMap?.get(result) || {};

  // const socket = useConnectionStore((state) => state.socket as Socket);
  //
  // const callId = useCallInfoStore((state) => state.call_id);
  // const clientId = useCallInfoStore((state) => state.call_data.client.client_id);
  // const refreshVadTotalSeconds = useVadInfoStore((state) => state.refreshVadTotalSeconds);

  // const handleEnrollResult = () => {
  //   if (isSuccess) {
  //     localStorage.removeItem('action');
  //     navigate('/');
  //     return;
  //   }
  //
  //   const params: EmitCommandParams = {
  //     socket,
  //     task_type: TaskType.ENROLL,
  //     call_id: callId,
  //     client_id: clientId,
  //   };
  //   localStorage.setItem('action', 'restart');
  //   refreshVadTotalSeconds();
  //   emitCommand(params);
  // };

  return (
    <div className={styles.resultWrapper}>
      {result === AntiSpoofStatus.ERROR && (
        <>
          <img src={icon} alt="error"/>
          <div className={cn(styles.statusText)} style={{ color: color }}>{text}</div>
          <div className={styles.errorTitle} style={{ color: color, marginTop: '10px' }}>{subText}</div>
        </>
      )}
      {result === AntiSpoofStatus.VERIFIED && (
        <>
          <img src={icon} alt="error"/>
          <div className={cn(styles.statusText)} style={{ color: color }}>{text}</div>
          <div className={styles.errorTitle} style={{ color: '#000', fontSize: '12px' }}>You can continue the process</div>
        </>
      )}
      {result === AntiSpoofStatus.FRAUD && (
        <>
          <img src={icon} alt=""/>
          <div className={cn(styles.statusText)} style={{ color: color }}>{text}</div>
          <div className={styles.descriptionText} style={{ color: color, marginTop: '10px' }} >Synthetic voice Alert!</div>
        </>)}

      {result === AntiSpoofStatus.WARNING && (
        <>
          <img src={icon} alt="error"/>
          <div className={cn(styles.statusText)} style={{ color: color }}>{text}</div>
          <div className={styles.errorTitle} style={{ color: '#000', fontSize: '12px' }} >Add another layer of verification</div>
        </>
      )}
      {/*{type !== 'hide' && (*/}
      {/*  <div className={styles.homeScreenBtn}>*/}
      {/*    <Button titleBtn={btnText as string} onClick={handleEnrollResult} />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default CallIdentify;
