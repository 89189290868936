import { create } from 'zustand';
import { AntiSpoofStatus } from "../utils/enum/AntiSpoofStatus";
import { TaskStatuses } from '../utils/enum/taskStatuses';
import { TaskType } from '../utils/enum/taskType';

interface TaskData {
  is_spoof: boolean | null;
  spoof_status: AntiSpoofStatus | null,
  score: null | number,
  threshold: null | number,
}

export interface AntiSpoofInfo {
  audio_id: string;
  task_id: string;
  task_type: TaskType;
  task_status: TaskStatuses | '';
  task_data: TaskData;
}

interface AntiSpoofInfoStore extends AntiSpoofInfo {
  updateAntiSpoofInfo: (newInfo: AntiSpoofInfo) => void;
  updateIsSpoof: (is_spoof: boolean | null) => void;
  updateAntiSpoofStatus: (task_status: TaskStatuses | '') => void;
}

export const useAntiSpoofInfoStore = create<AntiSpoofInfoStore>((set) => ({
  audio_id: '',
  task_id: '',
  task_type: TaskType.VERIFY,
  task_status: '',
  task_data: {
    spoof_status: null,
    score: null,
    threshold: null,
    is_spoof: null,
  },
  updateAntiSpoofInfo: (newInfo: AntiSpoofInfo) => set(newInfo),
  updateIsSpoof: (is_spoof: boolean | null) =>
    set((prev) => ({ ...prev, task_data: {...prev.task_data, is_spoof } })),
  updateAntiSpoofStatus: (task_status: TaskStatuses | '') =>
    set((prev) => ({ ...prev, task_status })),
}));
