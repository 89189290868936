import React, { FC, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAgeInfoStore } from '../../stores/useAgeInfo';
import { useAntiSpoofInfoStore } from '../../stores/useAntiSpoofInfo';
import { useCallInfoStore } from '../../stores/useCallInfo';
import { useGenderInfoStore } from '../../stores/useGenderInfo';
import { useVadInfoStore } from '../../stores/useVadllInfo';
import { useVerifyInfoStore } from '../../stores/useVerifyInfo';
import { useVoiceToFaceInfoStore } from '../../stores/useVoiceToFaceInfo';
import { AntiSpoofStatus } from "../../utils/enum/AntiSpoofStatus";
import { CallStatus } from '../../utils/enum/callStatuses';
import { TaskStatuses } from '../../utils/enum/taskStatuses';
import { TaskType } from '../../utils/enum/taskType';
import { handleEventMessage } from '../../utils/helpers/handleEventMessage';
import { CallActions } from "../Home/components/CallActions/CallActions";
import CallerInfo from '../Home/components/CallerInfo/CallerInfo';
import CallIdentify from '../Home/components/CallIdentify/CallIdentify';
// import { emitCommand, EmitCommandParams } from '../../utils/helpers/emitCommand';
import { useConnectionStore } from '../../stores/useConnection';
import { Socket } from 'socket.io-client';
import { useEthnicityInfoStore } from '../../stores/useEthnicityInfo';
import Progressbar from "../Home/components/Progressbar/Progressbar";
// import OperationResultScreen from '../Home/components/OperationResultScreen/OperationResultScreen';
// import { BottomInfo } from '../../components/BottomInfo/BottomInfo';

export const Verify: FC = () => {
  const navigate = useNavigate();

  const socket = useConnectionStore((state) => state.socket as Socket);

  // const callId = useCallInfoStore((state) => state.call_id);
  // const clientId = useCallInfoStore((state) => state.call_data.client.client_id);
  const callStatus = useCallInfoStore((state) => state.call_status);
  const taskStatus = useAntiSpoofInfoStore((state) => state.task_status);
  //   const isSuccess = useVerifyInfoStore((state) => state.task_data.is_success);
  const isSpoof = useAntiSpoofInfoStore((state) => state.task_data.is_spoof);
  const updateAntiSpoofStatus = useAntiSpoofInfoStore((state) => state.updateAntiSpoofStatus);
  const updateCallInfo = useCallInfoStore((state) => state.updateCallInfo);
  const updateVerifyInfo = useVerifyInfoStore((state) => state.updateVerifyInfo);
  const updateVerifyStatus = useVerifyInfoStore((state) => state.updateVerifyStatus);
  const updateIsVerifySuccess = useVerifyInfoStore((state) => state.updateIsVerifySuccess);
  const updateVadInfo = useVadInfoStore((state) => state.updateVadInfo);
  const updateAgeInfo = useAgeInfoStore((state) => state.updateAgeInfo);
  const updateGenderInfo = useGenderInfoStore((state) => state.updateGenderInfo);
  const updateVoiceToFaceInfo = useVoiceToFaceInfoStore((state) => state.updateVoiceToFaceInfo);
  const updateAntiSpoofInfo = useAntiSpoofInfoStore((state) => state.updateAntiSpoofInfo);
  const updateIsSpoof = useAntiSpoofInfoStore((state) => state.updateIsSpoof);
  const updateEthnicityInfo = useEthnicityInfoStore((state) => state.updateEthnicityInfo);
  const spoofStatus = useAntiSpoofInfoStore((state) => state.task_data.spoof_status);
  // const refreshVadTotalSeconds = useVadInfoStore((state) => state.refreshVadTotalSeconds);

  useEffect(() => {
    if (!socket) return;

    if (isSpoof) {
      updateVerifyStatus(TaskStatuses.FINISHED);
    }

    socket.on('event', (msg) => {
      handleEventMessage(socket, msg, {
        updateCallInfo,
        updateVerifyInfo: isSpoof ? undefined : updateVerifyInfo,
        updateVadInfo,
        updateAgeInfo,
        updateGenderInfo,
        updateVoiceToFaceInfo,
        updateAntiSpoofInfo: isSpoof ? undefined : updateAntiSpoofInfo,
        updateEthnicityInfo,
      });
    });

    if (callStatus === CallStatus.FINISHED || callStatus === '') {
      navigate('/');
      updateVerifyStatus('');
      updateAntiSpoofStatus('');
      updateIsSpoof(false);
      updateIsVerifySuccess(null);
    }

    return () => {
      socket.off('event');
    };
  }, [callStatus, taskStatus, isSpoof]);

  // const checkResult = () => {
  //   return isSpoof ? ActionStatuses.FRAUD_DETECTED : ActionStatuses.VERIFIED;
  // };

  // const checkResult = () => {
  //   if (taskStatus === TaskStatuses.ERROR) {
  //     return ActionStatuses.ERROR;
  //   }
  //
  //   if (taskStatus !== TaskStatuses.FINISHED) {
  //     return ActionStatuses.NOT_VERIFIED;
  //   }
  //
  //   return isSpoof ? ActionStatuses.FRAUD_DETECTED : ActionStatuses.VERIFIED };

  const checkResult = () => {
    if (taskStatus === TaskStatuses.ERROR) {
      return AntiSpoofStatus.ERROR;
    }
    return spoofStatus as AntiSpoofStatus;
  }

  const isFinished = () => {
    return taskStatus === TaskStatuses.FINISHED || taskStatus === TaskStatuses.ERROR;
  };

  // const restartVerification = () => {
  //   const params: EmitCommandParams = {
  //     socket,
  //     task_type: TaskType.VERIFY,
  //     call_id: callId,
  //     client_id: clientId,
  //   };
  //
  //   updateIsSpoof(null);
  //   updateVerifyStatus('');
  //   updateAntiSpoofStatus('');
  //   updateIsVerifySuccess(null);
  //
  //   refreshVadTotalSeconds();
  //   emitCommand(params);
  // };

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  return (
    <>
      {!token && <Navigate to={'/login'} replace={true} />}
      <CallerInfo animated isFinished={isFinished()} />
      {isFinished() ? (
        <>
          <CallIdentify result={checkResult()} />
          {/*<BottomInfo />*/}
          <CallActions/>
          {/*<OperationResultScreen result={checkResult()} onRestart={restartVerification} />*/}
        </>
      ) : (
        <>
          <Progressbar screenType={TaskType.VERIFY} />
          <CallActions/>
          {/*<BottomInfo />*/}
        </>
      )}
    </>
  );
};
